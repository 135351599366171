import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

const Hero = () => {
  return (
    <div
      className={`relative min-h-70vh] 800px:min-h-[70vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://www.lvnengebike.com/pcuploadfiles/128.1.164.27/webid822/MOD/202008/1598260968868.jpeg)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[75%]`}>
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Meilleure collection <br /> scooter électrique
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          Merveilleux, moderne & idéal pour vos sorties en ville<br />
            Moteur BOSCH & Système Double Batterie Lithium & Amovible 
          <br />Distribué en Tunisie exclusivement par la société Promotos .
        </p>
        <Link to="/products" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Demander Devis
                 </span>
            </div>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
