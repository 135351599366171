import React, { useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
  AiOutlineEye, AiOutlineCheck
} from "react-icons/ai";
import { FaPrint } from 'react-icons/fa';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { server, serverip } from "../../server";
import styles from "../../styles/styles";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";

import { MdTrackChanges } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { Country, State } from "country-state-city";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import lib from "redux-toolkit";
const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  console.log(user)
  const [name, setName] = useState(user && user.Nom + ' ' + user.Prenom);
  const [email, setEmail] = useState(user && user.Matricule);
  const [phoneNumber, setPhoneNumber] = useState(user && user.Tel);
  const [password, setPassword] = useState(user && user.Agence);
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="w-full">
      {/* profile */}
      {active === 1 && (
        <>
          <div className="flex justify-center w-full">
            <div className="relative">
              <img
                src={`${user?.avatar?.url}`}
                className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#3ad132]"
                alt=""
              />
              <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <input
                  type="file"
                  id="image"
                  className="hidden"
                // onChange={handleImage}
                />
                <label htmlFor="image">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="w-full px-5">
            <form onSubmit={handleSubmit} aria-required={true}>
              <div className="w-full 800px:flex block pb-3">

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Matricule</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Nom & Prénom</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Téléphone</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Agence</label>
                  <input
                    // type="password"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              {/* <input
                className={`w-[250px] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
                required
                value="Update"
                type="submit"
              /> */}
            </form>
          </div>
        </>
      )}

      {/* order */}
      {active === 2 && (
        <div>
          <AllOrders />
        </div>
      )}

      {/* Refund */}
      {active === 3 && (
        <div>
          <AllRefundOrders />
        </div>
      )}

      {/* Track order */}
      {active === 5 && (
        <div>
          <TrackOrder />
        </div>
      )}

      {/* Change Password */}
      {active === 6 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/*  user Address */}
      {active === 7 && (
        <div>
          <Address />
        </div>
      )}
    </div>
  );
};

const AllOrders = () => {
  var day = new Date();
  var dd = ("0" + (day.getDate())).slice(-2);
  var mm = ("0" + (day.getMonth() + 1)).slice(-2);

  const yyyy = day.getFullYear();
  const DateDebut = `${yyyy}-01-01`
  const DateFin = `${yyyy}-${mm}-${dd}`;


  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const [statusFilter, setStatusFilter] = useState([]);
  const [startDate, setStartDate] = useState(DateDebut);
  const [endDate, setEndDate] = useState(DateFin);

  const dispatch = useDispatch();

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    // Create a file and download it
    XLSX.writeFile(workbook, "orders.xlsx");
  };

  const statusOptions = [
    { id: "0", label: "Annulée" },
    { id: "1", label: "En attente" },
    { id: "2", label: "Devis Prêt" },
    { id: "3", label: "en Att. confirmation admin" },
    { id: "4", label: "Confirmée" },
    { id: "5", label: "Livrée" },

  ];

  useEffect(() => {
    
    dispatch(getAllOrdersOfUser(user?.Matricule));
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.4 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
    },
    { field: "CodTiers", headerName: "Code", flex: 0.5 },
    { field: "LibTiers", headerName: "Raison Sociale", minWidth: 150, flex: 0.7 },
    {
      field: "Tel",
      headerName: "Tél",

      flex: 0.4,
    },
    { field: "CodArt", headerName: "CodArt", flex: 0.5, hide: true  },
    { field: "LibArt", headerName: "LibArt", flex: 1.5 },
    { field: "MntTVA", headerName: "MntTVA", flex: 0.35, hide: true  },
    { field: "PuHT", headerName: "PuHT", flex: 0.35, hide: true  },
    { field: "PuTTC", headerName: "PuTTC", flex: 0.35, hide: true  },
    { field: "NumChass", headerName: "NumChass", flex: 0.5 },
    { field: "nbr", headerName: "validité", flex: 0.5 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.4,
      renderCell: (params) => {

        const { status } = params.row;

        let statusText;
        let statusClass;

        switch (status) {
          case "En attente":

            statusClass = 'bg-black text-white';
            break;
            case "Devis Prêt":

            statusClass = 'bg-orange-400 text-orange-100';
            break;
          case "En att. confirmation admin":

            statusClass = 'text-green-600 bg-green-100';
            break;
          case "Confirmée":

            statusClass = 'text-yellow-600 bg-yellow-100';
            break;
          case "Livrée":

            statusClass = 'text-teal-600 bg-teal-100';
            break;
          default:

            statusClass = 'text-red-600 bg-red-100';
        }

        return (
          <span className={`capitalize py-1 px-2 rounded-md text-xs ${statusClass}`}>
            {status.toLowerCase()}
          </span>
        );
      }
    },
    {
      field: "  ",
      flex: 0.8,

      headerName: ".",
      type: "number",
      sortable: false,

      renderCell: (params) => {
        const handlePrint = () => {
         
          const rowData = [] 
          rowData.push(params.row)   // Assuming params.row is your data object
  
  // Define the number of rows you want (20 rows)
  const totalRows = 10;

 
  const totalTable = `
  <table >
   
    <tbody>
      <tr>
        <td><strong>Total H.T</strong></td>
        <td style="text-align: right; border: 1px solid #000;">${params.row?.PuHT?.toFixed(3)}</td>
        </tr>
        
        <tr>
        <td><strong>Tot.Tva</strong></td>
        <td style="text-align: right; border: 1px solid #000;">${params.row?.MntTVA?.toFixed(3)}</td>
        </tr>
        <tr>
        <td><strong>Net à Payer</strong></td>
        <td style="text-align: right; border: 1px solid #000;">${params.row?.PuTTC?.toFixed(3)}</td>
      </tr>
    </tbody>
  </table>
  

`;
  // Build the content of the table rows
  let tableRows = '';
  
  // If rowData has data for multiple rows, loop through them (or just use one row)
  for (let i = 0; i < totalRows; i++) {
    if (i < rowData.length) {
      // If there's actual data (you can customize this depending on how you store your data)
      const currentRow = rowData[i]; // Access row i from data array (if available)
      tableRows += `
        <tr>
          <td>${currentRow.CodArt}</td>
          <td>${currentRow.LibArt}</td>
          <td style="text-align: right;">${currentRow?.PuHT ? currentRow.PuHT.toFixed(3) : ''}</td>
          <td style="text-align: center;">${currentRow?.Qte || 1}</td>
          <td style="text-align: center;">${currentRow?.MntTVA || 0}</td>
          <td style="text-align: center;">${(currentRow?.PuHT * (1 + currentRow?.MntTVA / 100) * (currentRow?.Qte || 1)).toFixed(2) || ''}</td>
        </tr>
      `;
    } else {
      // Add an empty row if there is no data for the row
      tableRows += `
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      `;
    }
  }

  const printContent = `
    <html>
      <head>
       
        <style>
          @media print {
            @page {
              size: A4;
              margin: 10mm;
            }
            body {
              font-family: Arial, sans-serif;
              font-size: 12pt;
              line-height: 1.5;
            }
            .info-container {
              display: flex;
              justify-content: space-between;
            }
            table {
              width: 97%;
              border: 1px solid #000;
              font-size: 10px;
              margin: 10px;
              border-collapse: collapse;
            }
            th, td {
              border-right: 1px solid #000;
              padding: 4px;
              text-align: left;
              height : 35px;
            }
            th {
              background-color: #bfbfbf;
            }
            h1 {
              font-size: 18pt;
              margin-bottom: 10px;
              text-align: center;
            }
            p {
              margin: 5px 0;
            }
            .Agence, .client {
              margin-top: 10px;
              width: 48%;
              padding: 5px;
            }
            hr {
              border: 0;
              height: 1px;
              background-color: #000;
              margin: 10px 0;
            }
              .total-section {
                 
                    display: flex;
                   justify-content: flex-end;
                    margin-top: 20px;
                  }
                  .total-section table {
                    width: 28%;
                  }

          }
        </style>
      </head>
      <body>
        <h1>Devis: ${params.row.id}</h1>
        <hr />
        <h4 class="font-bold text-3xl uppercase mb-1">PROMOTOS</h4>
        <div>Adresse : Route Gremda km 4.5</div>
        <div>Téléphone : +216 74 212 200</div>
        <div class="info-container">
          <div class="Agence">
            <div>Date du Devis: ${params.row.createdAt}</div>
            <div>Référence du Devis: ${params.row.id}</div>
            <div>Emis par: ${params.row.agent}</div>
            <div>Agence: ${params.row.Agence}</div>
          </div>
          <div class="client">
            <div>Banque Zitouna pour le code: ${params.row.CodTiers}</div>
            <div>Raison sociale: ${params.row.LibTiers}</div>
            <div>Adresse: ${params.row.Adresse}</div>
            <div>CIN: ${params.row.AssujTiers}</div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Réf.Art</th>
              <th>Désignation</th>
              <th>P.U.TTC</th>
              <th>Qte</th>
              <th>Tva%</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
          </tbody>
        </table>
        <div class="total-section">
            ${totalTable}
            </div>
      </body>
   
    </html>`

          const printWindow = window.open('', '', 'height=400,width=600');
          printWindow.document.write('<html><head><title>.</title></head><body>');
          printWindow.document.write(printContent);
          printWindow.document.write('</body></html>');
          printWindow.document.close();
          printWindow.print();
        };
        const handlevalider = () => {
          const rowData = params.row;
          axios.put(`${serverip}/commandes/valid/${rowData?.id}`)
            .then((res) => {
              if (res.data.success === true) {
                toast.success(res.data.message);

                window.location.reload()
              } else {
                toast.error(res.data.message);
              }

            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }
        const handleSupprimer = () => {
          const rowData = params.row;
          axios.put(`${serverip}/commandes/annuler/${rowData?.id}`)
            .then((res) => {
              if (res.data.success === true) {
                toast.success(res.data.message);

                window.location.reload()
              } else {
                toast.error(res.data.message);
              }

            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }
        return (
          <div>
           { params.row.status==='Devis Prêt' &&
            <Button onClick={handlevalider}>
              <AiOutlineCheck size={20} />
            </Button>}
            { params.row.status==='Devis Prêt' &&
            <Button onClick={handlePrint}>
              <FaPrint size={20} />
            </Button>
            }
            <Button onClick={handleSupprimer}>
              <AiOutlineDelete size={20} />
            </Button>
          </div>
        );
      },
    },



  ];



  const rows = [];

  orders &&
    orders.forEach((item) => {
      rows.push({
        id: item.Nf,
        createdAt: item?.MDate.slice(0, 10),
        CodTiers: item?.CodTiers,
        LibTiers: item?.LibTiers,
        CodArt : item?.CodArt ,
        LibArt: item?.LibArt,
        PuHT : item?.PuHT,
        PuTTC :item?.PuTTC ,
        MntTVA : item?.MntTVA,
        nbr: item?.nbr,
        NumChass: item?.NumChass,
        Matricule: item?.Matricule,
        agent: item?.agent,
        CodeAgence: item?.CodeAgence,
        Agence: item?.Agence,
        DR: item?.DR,
        ResponsableDR: item?.ResponsableDR,
        CodStatut: item?.CodStatut,
        status: item?.LibStatut
      });
    });
  const filteredRows = rows.filter(row => {
    const matchesStatut = statusFilter.length ? statusFilter.includes(row.CodStatut) : true;
    const rowDate = new Date(row.createdAt); // Assuming createdAt is in YYYY-MM-DD format
    const startDateValid = startDate ? rowDate >= new Date(startDate) : true;
    const endDateValid = endDate ? rowDate <= new Date(endDate) : true;

    return matchesStatut && startDateValid && endDateValid;;
  });

  
  const countOrdersByStatus = () => {
    const counts = {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      // Add more statuses if needed
    };

    filteredRows.forEach(row => {
      counts[row.CodStatut] = (counts[row.CodStatut] || 0) + 1;
    });

    return counts;
  };

  const orderCounts = countOrdersByStatus();

  return (
    <div>
      <div className="flex gap-4 p-2">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Commandes</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{filteredRows.length}</strong>

            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-red-600">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Annulées</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{orderCounts["0"]}</strong>

            </div>
          </div>
        </BoxWrapper>

        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-black">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">En attente</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{orderCounts["1"]}</strong>

            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-400">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Devis prét</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{orderCounts["2"]}</strong>

            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">En Att. confirmation admin</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{orderCounts["3"]}</strong>

            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Confirmées</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{orderCounts["4"]}</strong>

            </div>
          </div>
        </BoxWrapper>

        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-violet-400">
            <IoCart className="text-2xl text-white" />
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Livrées</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{orderCounts["5"]}</strong>

            </div>
          </div>
        </BoxWrapper>
      </div>
      <div className="flex space-x-4 mb-4 p-2">

        <div >
          <label>
            Date Début
          </label>
          <input
            type="date"
            name="price"
            id="start-date"

            value={startDate}
            className="mt-2 appearance-none block w-full px-3 h-[37px] border border-gray-300 rounded-[5px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStartDate(e.target.value)}
            //  onChange={handleStartDateChange}
            // min={today}
            placeholder="Enter your event product stock..."
          />
        </div>
        <div>
          <label>
            Date Fin
          </label>
          <input
            type="date"
            name="price"
            id="start-date"
            value={endDate}
            className="mt-2 appearance-none block w-full px-3 h-[37px] border border-gray-300 rounded-[5px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            //  onChange={handleEndDateChange}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Enter your event product stock..."
          />
        </div>
        <div>
          <label className="pb-2">
            Statut
          </label>
          <Typeahead
            id="status-filter"
            onChange={(selected) => setStatusFilter(selected.map(item => item.id))}
            options={statusOptions}
            placeholder="Rechercher status..."
            selected={statusOptions.filter(option => statusFilter.includes(option.id))}
            multiple
            labelKey="label" // Display the label in the dropdown
          />

        </div>



        <button
          onClick={handleExportToExcel}
          className=" px-4 py-2 bg-blue-600 text-white rounded"
        >
          Export to Excel
        </button>
      </div>
      <div >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={10}
          
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
};
const BoxWrapper = ({ children }) => {
  return <div className="bg-white rounded-sm p-2 flex-1 border border-gray-200 flex items-center">{children}</div>
}

const AllRefundOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const eligibleOrders =
    orders && orders.filter((item) => item.status === "Processing refund");

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  eligibleOrders &&
    eligibleOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "US$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
  );
};

const TrackOrder = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/track/order/${params.id}`}>
              <Button>
                <MdTrackChanges size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "US$ " + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
    </div>
  );
};

const ChangePassword = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();
    

    await axios
      .put(
        `${serverip}/user/update-user-password/${user?.Matricule}`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          navigate("/");
        } else {
          toast.error(res.data.message);
        }

      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
        Changer Mot de passe
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Entrez votre ancien mot de passe</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Entrez votre nouveau mot de passe</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Entrez votre mot de passe de confirmation</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className={`w-[95%] h-[40px] border border-[#3a24db] text-center text-[#3a24db] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Enregistrer"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const Address = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState();
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    {
      name: "Default",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(
        updatUserAddress(
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType
        )
      );
      setOpen(false);
      setCountry("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode(null);
      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };

  return (
    <div className="w-full px-5">
      {open && (
        <div className="fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center ">
          <div className="w-[35%] h-[80vh] bg-white rounded shadow relative overflow-y-scroll">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className="text-center text-[25px] font-Poppins">
              Add New Address
            </h1>
            <div className="w-full">
              <form aria-required onSubmit={handleSubmit} className="w-full">
                <div className="w-full block p-4">
                  <div className="w-full pb-2">
                    <label className="block pb-2">Country</label>
                    <select
                      name=""
                      id=""
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                        choose your country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Choose your City</label>
                    <select
                      name=""
                      id=""
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                        choose your city
                      </option>
                      {State &&
                        State.getStatesOfCountry(country).map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Address 1</label>
                    <input
                      type="address"
                      className={`${styles.input}`}
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                  <div className="w-full pb-2">
                    <label className="block pb-2">Address 2</label>
                    <input
                      type="address"
                      className={`${styles.input}`}
                      required
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Zip Code</label>
                    <input
                      type="number"
                      className={`${styles.input}`}
                      required
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Address Type</label>
                    <select
                      name=""
                      id=""
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                        Choose your Address Type
                      </option>
                      {addressTypeData &&
                        addressTypeData.map((item) => (
                          <option
                            className="block pb-2"
                            key={item.name}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className=" w-full pb-2">
                    <input
                      type="submit"
                      className={`${styles.input} mt-5 cursor-pointer`}
                      required
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="flex w-full items-center justify-between">
        <h1 className="text-[25px] font-[600] text-[#000000ba] pb-2">
          My Addresses
        </h1>
        <div
          className={`${styles.button} !rounded-md`}
          onClick={() => setOpen(true)}
        >
          <span className="text-[#fff]">Add New</span>
        </div>
      </div>
      <br />
      {user &&
        user.addresses.map((item, index) => (
          <div
            className="w-full bg-white h-min 800px:h-[70px] rounded-[4px] flex items-center px-3 shadow justify-between pr-10 mb-5"
            key={index}
          >
            <div className="flex items-center">
              <h5 className="pl-5 font-[600]">{item.addressType}</h5>
            </div>
            <div className="pl-8 flex items-center">
              <h6 className="text-[12px] 800px:text-[unset]">
                {item.address1} {item.address2}
              </h6>
            </div>
            <div className="pl-8 flex items-center">
              <h6 className="text-[12px] 800px:text-[unset]">
                {user && user.phoneNumber}
              </h6>
            </div>
            <div className="min-w-[10%] flex items-center justify-between pl-8">
              <AiOutlineDelete
                size={25}
                className="cursor-pointer"
                onClick={() => handleDelete(item)}
              />
            </div>
          </div>
        ))}

      {user && user.addresses.length === 0 && (
        <h5 className="text-center pt-8 text-[18px]">
          You not have any saved address!
        </h5>
      )}
    </div>
  );
};
export default ProfileContent;
