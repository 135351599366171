import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server, serverip } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from "react-redux";
import { Result } from "postcss";
import { RoleOptions } from "../../static/data";
const ShopCreate = () => {
  const navigate = useNavigate();
  const { sellers } = useSelector((state) => state.seller);
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [Prenom, setPrenom] = useState();
  const [Matricule, setMatricule] = useState("");
  const [zipCode, setZipCode] = useState();
  const [avatar, setAvatar] = useState();
  const [password, setPassword] = useState("");
  const [Agence, setAgence] = useState(null)
  const [role, setrole] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(`${serverip}/user/create-user`, {
        Matricule,
        role :role [0]['label'],
        Agence : Agence[0]['code'],
        nom,
        Prenom
      })
      .then((res) => {
        console.log(res.data)

        if (res.data.success == true) {
          toast.success(res.data.message);
          navigate("/admin-users")
        } else {
          toast.success(res.data.message);

        }

      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };


  return (

    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          S'inscrire en tant que Agent
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Matricule
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="Matricule"
                  required
                  value={Matricule}
                  onChange={(e) => setMatricule(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label

                className="block text-sm font-medium text-gray-700"
              >
                Nom
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  name="name"
                  required
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Prénom
              </label>
              <div className="mt-1">
                <input

                  name="phone-number"
                  required
                  value={Prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Agence
              </label>
              <Typeahead
                className="mt-1"
                id="status-filter"
                onChange={(selected) => setAgence(selected)}
                options={sellers}
                placeholder=""
                selected={Agence}

                labelKey="label" // Display the label in the dropdown
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Role
              </label>
              <Typeahead
                
                defaultValue={role}
                onChange={(item) => { setrole(item)}}
                options={RoleOptions}
                placeholder=""
                selected={role}
              />
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Enregistrer
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;
