import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin ,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";

import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import Logo from "../../Assests/100053.jpg";

const Footer = () => {
  return (
    <div className="bg-[#000] text-white">
      
      <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-6 sm:px-2 px-5 py-8 sm:text-center">
        <ul className="px-2 text-center sm:text-start flex sm:block flex-col items-center">
          <img
            src={Logo}
            alt=""
             style={{ width :'200px' }}
          />
          {/* <br />
           <p>Distributeur en gros des pièces de rechange & d’accessoires pour cycles,motocycles et scooters.</p> 
          */}
        </ul>

        

       

        <ul className="text-center sm:text-start">
        <div className="flex items-center mt-[15px]">
          <a href="https://www.facebook.com/Promotos-1394046130699391" target="_blank" rel="noopener noreferrer">
           <AiFillFacebook size={25} className="cursor-pointer" />
          </a>

          <a href="https://www.instagram.com/promotostn/" target="_blank" rel="noopener noreferrer">
            <AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
             </a>
             <a href="https://www.linkedin.com/in/your-linkedin-profile" target="_blank" rel="noopener noreferrer" style={{ marginLeft: "15px" }}>
    <AiFillLinkedin size={25} className="cursor-pointer" />
  </a>
          </div>
        </ul>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10
         text-center pt-2 text-gray-400 text-sm pb-8"
      >
        <span>© 2024 PROMOTOS | Tous droits réservés | BUSINESS SOFTWARE</span>
       
        {/* <div className="sm:block flex items-center justify-center w-full">
          <img
            src="https://hamart-shop.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Ffooter-payment.a37c49ac.png&w=640&q=75"
            alt=""
          />
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
